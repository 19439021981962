.ReactTokenInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  padding: .5rem 0;
  max-width: 320px;
  margin: 0 0 1rem;
  border-bottom: 1px solid #ccc; }

.ReactTokenInput__loading-label {
  display: block;
  padding: 5px 0;
  color: #ccc;
  text-align: center;
  font-size: 12px; }

.ReactTokenInput__token {
  border-radius: 3px;
  background: #14b3ff;
  color: #fff !important;
  font-size: 12px;
  line-height: 1.5;
  margin: 5px 5px 5px 0;
  padding: 2px 4px; }
  .ReactTokenInput__token .icon-times {
    cursor: pointer;
    margin: 0 3px;
    font-size: 1.2em; }

.ReactTokenInput__input-col:first-child:last-child {
  width: 100%; }

.ReactTokenInput__input {
  width: 100%;
  height: calc(2em + 3px);
  border: 0;
  padding: 0; }

.ReactTokenInput__options-list {
  display: none;
  position: absolute;
  top: 100%;
  background-color: #fff;
  left: 0;
  width: 100%;
  z-index: 1;
  max-height: 250px;
  overflow: scroll;
  border: 1px solid #ccc; }
  .ReactTokenInput--open .ReactTokenInput__options-list {
    display: block; }

.ReactTokenInput__option {
  display: block;
  padding: .6em;
  border-bottom: 1px solid #ccc;
  cursor: default; }
